exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cuenta-creada-tsx": () => import("./../../../src/pages/cuenta-creada.tsx" /* webpackChunkName: "component---src-pages-cuenta-creada-tsx" */),
  "component---src-pages-cuenta-tsx": () => import("./../../../src/pages/cuenta.tsx" /* webpackChunkName: "component---src-pages-cuenta-tsx" */),
  "component---src-pages-finalizar-tsx": () => import("./../../../src/pages/finalizar.tsx" /* webpackChunkName: "component---src-pages-finalizar-tsx" */),
  "component---src-pages-identificacion-datospersonales-tsx": () => import("./../../../src/pages/identificacion-datospersonales.tsx" /* webpackChunkName: "component---src-pages-identificacion-datospersonales-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-referencias-tsx": () => import("./../../../src/pages/referencias.tsx" /* webpackChunkName: "component---src-pages-referencias-tsx" */),
  "component---src-pages-verificar-identidad-tsx": () => import("./../../../src/pages/verificar-identidad.tsx" /* webpackChunkName: "component---src-pages-verificar-identidad-tsx" */)
}

