import { createReducer } from '@reduxjs/toolkit';

import { UserInfo, authActions } from './auth.actions';

interface AuthState {
  state: 'init' | 'loaded',
  userInfo?: UserInfo
}

const initialState = { state: 'init' } as AuthState;

export const authReducer = createReducer(initialState, builder => {
  builder.addCase(authActions.initAuth.fulfilled, () => {
    return {
      userInfo: undefined,
      state: 'loaded',
    };
  }).addCase(authActions.setAuth, (slice, action) => {
    return {
      userInfo: action.payload,
      state: 'loaded'
    }
  });
});
