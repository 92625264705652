import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useMemo, useState } from 'react';
import { useBreakpoint } from 'use-breakpoint';
import { useSessionStorage } from 'usehooks-ts';
import { UserInfo } from '../store/auth/auth.actions';
import { STEP_CUENTA, STEP_FINALIZAR, STEP_IDENTIFICACION_DATOSPERSONALES, STEP_REFERENCIAS, STEP_VERIFICAR_IDENTIDAD } from '../utils/fetch';

interface TNavigatorProps {
  index: number;
  disableNavigation?: boolean;
}

interface TOverlayNavigatorProps extends TNavigatorProps {
  open: boolean;
  onClose(value: boolean): void;
}

export const BREAKPOINTS = { '2xl': 1536, lg: 1024, md: 768, sm: 0, xl: 1280 };

const Steps = [
  // 'Bienvenido',
  'Identificación y Domicilio',
  'Referencias',
  // 'Cuenta',
  'Verificar Identidad',
  'Felicidades',
];

const StepKeys = [
  STEP_IDENTIFICACION_DATOSPERSONALES,
  STEP_REFERENCIAS,
  // STEP_CUENTA,
  STEP_VERIFICAR_IDENTIDAD,
  STEP_FINALIZAR,
];

export const PATH_STEPS = {
  [STEP_IDENTIFICACION_DATOSPERSONALES]: '/identificacion-datospersonales',
  [STEP_REFERENCIAS]: '/referencias',
  // [STEP_CUENTA]: '/cuenta',
  [STEP_VERIFICAR_IDENTIDAD]: '/verificar-identidad',
  [STEP_FINALIZAR]: '/finalizar',
};

export const Navigator2 = ({ index }: { index: number }) => {
  return (
    <div>
      {Steps.map((step, i) => (
        <div key={i}>
          {step} {index === i && '(current)'} |{' '}
        </div>
      ))}
    </div>
  );
};

const steps = [
  // { description: '', href: '/', id: '1', name: 'Bienvenido', path: '', status: 'complete' },
  { description: '', href: PATH_STEPS[STEP_IDENTIFICACION_DATOSPERSONALES], id: '1', name: 'Datos Personales', path: '', status: 'current' },
  { description: '', href: PATH_STEPS[STEP_REFERENCIAS], id: '2', name: 'Referencias', path: '', status: 'upcoming' },
  // { description: '', href: PATH_STEPS[STEP_CUENTA], id: '3', name: 'Cuenta', path: '', status: 'upcoming' },
  { description: '', href: PATH_STEPS[STEP_VERIFICAR_IDENTIDAD], id: '3', name: 'Verificar Identidad', path: '', status: 'upcoming' },
  { description: '', href: PATH_STEPS[STEP_FINALIZAR], id: '4', name: 'Finalizar', path: '', status: 'upcoming' },
];

const getSteps = (index: number) => {
  return steps.map((item, i) =>
    Object.assign([], item, { status: i < index ? 'complete' : i > index ? 'upcoming' : 'current' }),
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function DesktopNavigator({ index, disableNavigation }: TNavigatorProps) {
  const [userStep, _] = useSessionStorage<string | undefined>('userStep', undefined);

  const maxIndexUnlocked = userStep ? StepKeys.indexOf(userStep) : 0;
  // const maxIndexUnlocked = 1;

  console.log({ maxIndexUnlocked });

  const steps = useMemo(() => getSteps(index), [userStep]);



  return (
    <div className='lg:border-b lg:border-t lg:border-gray-200'>
      <nav className={`mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8 ${disableNavigation && 'pointer-events-none'}`} aria-label='Progress'>
        <ol className='overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200'>
          {steps.map((step, stepIdx) => (
            <li key={step.id} className='relative overflow-hidden lg:flex-1'>
              <div
                className={classNames(
                  stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
                  stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
                  'overflow-hidden border border-gray-200 lg:border-0',
                  stepIdx > maxIndexUnlocked ? 'cursor-not-allowed' : ''
                )}
              >
                {step.status === 'complete' ? (
                  <a href={step.href} className='group'>
                    <span
                      className='absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full'
                      aria-hidden='true'
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex md:flex-col md:items-center 2xl:flex-row 2xl:items-start px-6 py-5 text-sm font-medium',
                      )}
                    >
                      <span className='flex-shrink-0'>
                        <span className='flex h-10 w-10 items-center justify-center rounded-full bg-green2'>
                          <CheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
                        </span>
                      </span>
                      <span className='ml-4 md:ml-0 2xl:ml-4 mt-0.5 md:mt-2 2xl:mt-0.5 flex min-w-0 flex-col'>
                        <span className='text-sm font-medium text-green1  md:text-center 2xl:text-left'>
                          {step.name}
                        </span>
                        {/* <span className="text-sm font-medium text-gray-500">{step.description}</span> */}
                      </span>
                    </span>
                  </a>
                ) : step.status === 'current' ? (
                  <a href={step.href} aria-current='step'>
                    <span
                      className='absolute left-0 top-0 h-full w-1 bg-green2 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full'
                      aria-hidden='true'
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex md:flex-col md:items-center 2xl:flex-row 2xl:items-start px-6 py-5 text-sm font-medium',
                      )}
                    >
                      <span className='flex-shrink-0'>
                        <span className='flex h-10 w-10 items-center justify-center rounded-full border-2 border-green2'>
                          <span className='text-green2'>{step.id}</span>
                        </span>
                      </span>
                      <span className='ml-4 md:ml-0 2xl:ml-4 mt-0.5 md:mt-2 2xl:mt-0.5 flex min-w-0 flex-col'>
                        <span className='text-sm font-medium text-green1  md:text-center 2xl:text-left'>
                          {step.name}
                        </span>
                        {/* <span className="text-sm font-medium text-gray-500">{step.description}</span> */}
                      </span>
                    </span>
                  </a>
                ) : (
                  <a href={step.href} className={`group ${stepIdx > maxIndexUnlocked && 'pointer-events-none'} `}>
                    <span
                      className='absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-500 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full'
                      aria-hidden='true'
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex md:flex-col md:items-center 2xl:flex-row 2xl:items-start px-6 py-5 text-sm font-medium',
                      )}
                    >
                      <span className='flex-shrink-0'>
                        {
                          stepIdx > maxIndexUnlocked ?
                            <span className='flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-500 group-hover:border-green2'>
                              <span className='text-gray-500 group-hover:text-green2'>{step.id}</span>
                            </span>
                            :
                            <span className='flex h-10 w-10 items-center justify-center rounded-full bg-green2'>
                              <CheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
                            </span>
                        }

                      </span>
                      <span className='ml-4 md:ml-0 2xl:ml-4 mt-0.5 md:mt-2 2xl:mt-0.5 flex min-w-0 flex-col'>
                        <span className={`text-sm font-medium ${stepIdx > maxIndexUnlocked ? 'text-gray-600' : 'text-green1'} md:text-center 2xl:text-left`}>
                          {step.name}
                        </span>
                        {/* <span className="text-sm font-medium text-gray-500">{step.description}</span> */}
                      </span>
                    </span>
                  </a>
                )}

                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div className='absolute inset-0 left-0 top-0 hidden w-3 lg:block' aria-hidden='true'>
                      <svg
                        className='h-full w-full text-gray-300'
                        viewBox='0 0 12 82'
                        fill='none'
                        preserveAspectRatio='none'
                      >
                        <path d='M0.5 0V31L10.5 41L0.5 51V82' stroke='currentcolor' vectorEffect='non-scaling-stroke' />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

function OverlayNavigator({ index, open, onClose }: TOverlayNavigatorProps) {
  const [userInfo, _] = useSessionStorage<UserInfo | undefined>('userInfo', undefined);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
                          Apertura de cuenta
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            onClick={() => onClose(false)}
                          >
                            <span className='absolute -inset-2.5' />
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      {
                        !!userInfo && <div className='min-w-24 pr-6 items-center text-blue1 font-light text-sm text-right mb-2'>{userInfo.email}</div>
                      }
                      <DesktopNavigator index={index} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function MobileNavigator({ index, disableNavigation }: TNavigatorProps) {
  const [open, setOpen] = useState(false);
  const [userStep, _] = useSessionStorage<string | undefined>('userStep', undefined);
  const maxIndexUnlocked = userStep ? StepKeys.indexOf(userStep) : 0;

  //const maxIndexUnlocked  = 3;
  const steps = getSteps(index);

  return (
    <>
      <OverlayNavigator index={index} open={open} onClose={setOpen} />
      <nav
        className={`flex items-center justify-center py-2 border-b ${disableNavigation && 'pointer-events-none'}`}
        aria-label='Progress'
        onClick={() => setOpen(true)}
        aria-hidden='true'
      >
        <p className='text-sm font-medium text-green1'>
          Paso {steps.findIndex(step => step.status === 'current') + 1} de {steps.length}
        </p>
        <ol className='ml-8 flex items-center space-x-5'>
          {steps.map((step, stepIdx) => (
            <li key={step.name}>
              {step.status === 'complete' ? (
                <a href={step.href} className='block h-2.5 w-2.5 rounded-full bg-green1 hover:bg-green2'>
                  <span className='sr-only'>{step.name}</span>
                </a>
              ) : step.status === 'current' ? (
                <a href={step.href} className='relative flex items-center justify-center' aria-current='step'>
                  <span className='absolute flex h-5 w-5 p-px' aria-hidden='true'>
                    <span className='h-full w-full rounded-full bg-green5' />
                  </span>
                  <span className='relative block h-2.5 w-2.5 rounded-full bg-green1' aria-hidden='true' />
                  <span className='sr-only'>{step.name}</span>
                </a>
              ) : (
                <a href={step.href} className={`block h-2.5 w-2.5 rounded-full hover:bg-green1 ${stepIdx > maxIndexUnlocked ? 'pointer-events-none bg-gray-200' : 'bg-green2'} `} >
                  <span className='sr-only'>{step.name}</span>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}

export function Navigator(props: TNavigatorProps) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const isMobile = breakpoint === 'sm' || breakpoint === 'md';
  const isDesktop = breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === '2xl';

  return (
    <>
      {isMobile && (
        <>
          <MobileNavigator {...props} />
        </>
      )}
      {isDesktop && <DesktopNavigator {...props} />}
    </>
  );
}
