import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export interface UserInfo {
  name?: string;
  lastname?: string;
  token?: string;
  sequence: string;
  email: string;
}

export const authActions = {
  initAuth: createAsyncThunk<UserInfo>('auth/init', async () => {
    // const authClient = await getAuthClient();

    // await authClient.goToSignIn({
    //   redirectUri: getDynamicRedirectUri(),
    // });
    return { lastname: 'Boy', name: 'Dummy', token: '', sequence: '', email: '' };
  }),
  setAuth: createAction<UserInfo>("auth/set")
};
